import { useIsomorphicLayoutEffect } from 'ahooks';
import React from 'react';

export const useIsSafari = () => {
    const [isSafari, setIsSafari] = React.useState(false);

    useIsomorphicLayoutEffect(() => {
        const userAgent = navigator?.userAgent;
        setIsSafari(
            /safari/i.test(userAgent) && !/chrome|edge/i.test(userAgent)
        );
    }, []);
    return isSafari;
};
